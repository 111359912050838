import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import InputMask from "react-input-mask";

import preAssembleia from "../../../assets/landingpage/unicred/pre-assembleia.png";
import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconTwitter from "../../../assets/landingpage/unicred/twitter.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import iconWhatsapp from "../../../assets/landingpage/unicred/whatsapp.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";
import unicredSelo from "../../../assets/landingpage/unicred/unicred-vale-selo.jpg";

import api from "~/services/api";
import "./styles.scss";

function Unicred({ branch, domain }) {
  const [message, setMessage] = useState("");
  const [isCNPJ, setIsCNPJ] = useState(false);
  const [form, setForm] = useState({
    name: "",
    document_number: "",
    email: "",
    phone: ""
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  function handleInput(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setForm({
      ...form,
      [name]: value
    });
  }

  function handleCpfCnpjChange(event) {
    let data = { ...form };
    const { value } = event.target;
    const oldLength = form.document_number.replace(/\D/g, '').length;
    const newLength = value.replace(/\D/g, '').length;

    const isNewCNPJ = (oldLength === 11 && newLength >= 11) || newLength > 11;

    if (isNewCNPJ !== isCNPJ) {
      setIsCNPJ(isNewCNPJ);
    }

    data[event.target.name] = value;
    setForm(data);
  }

  function handleSubmit(event) {
    event.preventDefault();

    const body = {
      branch,
      ...form
    }

    api.post("/v1/livestreams/attendant", body)
      .then(() => {
        setMessage("Incrição realizada com sucesso.");
        setForm({
          name: "",
          document_number: "",
          email: "",
          phone: ""
        });
      })
      .catch((err) => {
        console.log(err);
        setMessage("Não conseguimos realizar sua inscrição.");
      });
  }

  return (
    <div className="lp-unicred">
      <div className="section-main">
        <div className="section-shadow" />
        <div className="section-main-container wrap">
          <div className="section-main-info">
            <h1>
              <p>VOCÊ ESTÁ NA</p>
              <span> PRÉ-ASSEMBLEIA UNICRED VALE 2025!</span>
            </h1>

            <p>Que bom ter você aqui!</p>
            <p>Sua participação fortalece nossa cooperativa e garante que cada decisão seja tomada com transparência e alinhada aos interesses de todos os cooperados.</p>
            <p>Aqui, você tem acesso às informações essenciais para acompanhar a prestação de contas e contribuir com o futuro da Unicred Vale.</p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo">
        <div className="section-2-container wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">NOSSO PRESIDENTE TEM UMA <span>MENSAGEM ESPECIAL PARA VOCÊ</span></h1>
            <p>Assista ao vídeo do Dr. Wálmore Pereira de Siqueira Júnior.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-vale-dr-walmore.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#e9e9e9" }}>
        <div className="section-2-container wrap">
          <div className="section-2-video-left">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-vale-realizacoes.m4v" />
              </video>
            </div>
          </div>

          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">CLIQUE PARA VER AS <span>PRINCIPAIS AÇÕES DE 2024</span></h1>
            <p style={{ color: "#2f2b28" }}>Veja tudo o que construímos ao longo do ano e como sua participação impulsiona nossos resultados.</p>
          </div>
        </div>
      </div> 

      <div className="section-2 without-selo">
        <div className="section-2-container wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">VEJA EM DETALHES AS <span>DEMONSTRAÇÕES CONTÁBEIS</span></h1>
            <p>A evolução dos números da cooperativa com o diretor de operações, Rafael Peters Pereira.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-vale-balanco-financeiro.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#e9e9e9" }}>
        <div className="section-2-container wrap">
          <div className="section-2-video-left">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-vale-agnaldo.m4v" />
              </video>
            </div>
          </div>

          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">RUMO A UM <span>FUTURO AINDA MELHOR</span></h1>
            <p style={{ color: "#2f2b28" }}>
              Assista à mensagem do diretor executivo, Agnaldo Leandro Ábila, e veja como estamos preparando a cooperativa para novos desafios.<br /><br />
              Juntos, construímos uma Unicred Vale cada vez mais forte. Contamos com você!
            </p>
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#202020" }}>
        <div className="section-form-container wrap">
          <div className="section-form-info">
            <p>Agora que você está por dentro das informações sobre a sua cooperativa, anote na agenda: A Assembleia Geral Ordinária da Unicred Vale será no dia 17 de março de 2025, em formato digital pelos canais digitais da Unicred (mobile e IB).</p>
          </div>

          <div className="section-form-submit">
            <div className="section-form-title">Caso não seja cooperado preencher as informações abaixo.</div>

            <form onSubmit={handleSubmit}>
              <div className="form-input">
                <label htmlFor="name">Nome:</label>
                <input
                  id="name"
                  name="name"
                  value={form.name}
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="form-input">
                <label htmlFor="document_number">CPF ou CNPJ:</label>
                <InputMask
                  id="document_number"
                  name="document_number"
                  type="text"
                  mask={isCNPJ ? "99.999.999/9999-99" : "999.999.999-999"}
                  maskPlaceholder={""}
                  maskChar={null}
                  onChange={handleCpfCnpjChange}
                  value={form.document_number}
                  required
                />
              </div>
              <div className="form-input">
                <label htmlFor="email">E-mail:</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={form.email}
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="form-input" style={{ borderBottom: 0 }}>
                <label htmlFor="phone">Telefone:</label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={form.phone}
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="form-input" style={{ borderBottom: 0, padding: 0 }}>
                <small>*Lembrando que essa solicitação será apenas para não cooperado.</small>
              </div>
              <div className="form-button">
                <div>
                  {message && <div className="form-message">{message}</div>}
                  <button>Enviar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="section-links" style={{ backgroundColor: "#191817" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-vale-edital-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital
                </a>
              </li>

              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-vale-estatuto-social.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Estatuto Social
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-manual-web-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso computador
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-manual-mobile-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso celular
                </a>
              </li>
            </ul>
          </div>

          <div className="section-links-info">
            <h1>Ficou com alguma dúvida?</h1>
            <p>Converse com o seu Gerente de Relacionamento ou envie sua dúvida para o e-mail <a href="mailto:assembleia@unicred.com.br">assembleia@unicred.com.br</a> ou WhatsApp <span><img src={iconWhatsapp} /></span> <a href="https://wa.me/5547991376094" target="_blank">(47) 9 9137-6094</a>.</p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="https://unicred.com.br" target="_blank">unicred.com.br</a>

            <div className="footer-info-box">
              <h2 style={{ marginBottom: 0 }}>Central de Relacionamento Unicred</h2>
              <h2>24 horas por dia, 7 dias por semana</h2>
              <p>3003 7703 (Capitais e Regiões Metropolitanas)</p>
              <p>0800 200 7302 (Demais Localidades)</p>
              <p>+55 800 200 7302 (WhatsApp)</p>
              <p>+55 11 3003 7703 (Ligações do Exterior)</p>
              <p>Chat no app Unicred Mobile e Internet Banking</p>
            </div>

            <div className="footer-info-box">
              <h2>SAC</h2>
              <p>0800 647 2930</p>
            </div>

            <div className="footer-info-box">
              <h2>Ouvidoria</h2>
              <p>0800 940 0602</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredbrasil/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredbrasil" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/unicredbrasil" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCdowVMAIRENRC2VH0_gcdww" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredbr" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredSelo} alt="Prêmio SomosCoop" />
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unicred;
