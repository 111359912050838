import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconTwitter from "../../../assets/landingpage/unicred/twitter.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import iconWhatsapp from "../../../assets/landingpage/unicred/whatsapp.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";

import "./styles.scss";

function Unicred({ domain }) {
  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  return (
    <div className="lp-unicred">
      <div className="section-main">
        <div className="section-shadow" />
        <div className="section-main-container wrap">
          <div className="section-main-info">
            <h1>
              Pré-Assembleia Geral Ordinária da
              <span>Unicred</span>
              <span>Valor Capital</span>
            </h1>

            <p>
              Que bom que você está aqui!<br />
              Sua presença e voto são fundamentais para definirmos o futuro da nossa cooperativa. Abaixo compartilhamos materiais da pré-assembleia. Leia, assista aos vídeos e programe-se para participar da nossa Assembleia no dia 01/04/2025 às 19h (última chamada).
            </p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#191817" }}>
        <div className="section-2-container section-2-container--align-center wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <p><strong>Iniciamos com</strong> a mensagem do Presidente do Conselho de Administração, Dr. Carlos Gilberto Crippa.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-valor-capital-dr-crippa.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#e9e9e9" }}>
        <div className="section-2-container section-2-container--align-center wrap">
          <div className="section-2-video-left">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-valor-capital-regional-conexao.m4v" />
              </video>
            </div>
          </div>

          <div className="section-2-info section-2-info-unicred-vale">
            <p style={{ color: "#2f2b28" }}>2024 foi um ano especial para a Unicred. Abaixo disponibilizamos algumas das realizações da Unicred Central Conexão, núcleo regional do qual a Valor Capital faz parte.</p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#191817" }}>
        <div className="section-2-container section-2-container--align-center wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <p>Chegou a hora de apresentarmos os resultados da cooperativa no exercício de 2024, narrado por nossa Diretora de Operações, Débora Setubal. Clique no vídeo e confira.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-valor-capital-balanco-financeiro.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#2c2b2b" }}>
        <div className="wrap">
          <div className="section-agencies">
            <p>A Assembleia Geral Extraordinária e Ordinária da Unicred Valor Capital acontecerá no Centro de Eventos do Square SC, localizado na Rod. José Carlos Daux, 5502, Business Center – 2º andar, Florianópolis – SC, no dia 01/04/25, às 19h (última chamada) e será transmitida em tempo real a todos os cooperados via app Unicred (mobile) ou Internet Banking (desktop).</p>
            <p>Nesta edição cooperados da Unicred Valor Capital poderão se inscrever antecipadamente neste <a href="https://www.pensanoevento.com.br/eventos/76491/ageo-unicred-valor-capital" target="_blank">link</a> para participarem da AGEO presencialmente no Centro de Eventos do Square SC (vagas limitadas). As agências citadas abaixo também estarão abertas para recepcioná-lo, mediante confirmação de sua presença até o dia 21/03, diretamente na agência no qual deseja participar (vagas limitadas).</p>
            <p>Todos os cooperados que participarem da AGEO, tanto no acompanhamento presencial, quanto online, deverão votar pelo app Unicred (mobile) ou internet banking (desktop).</p>
            <p>Agências que estarão abertas:</p>

            <div className="section-agencies-list">
              <p>ESTADO DE SANTA CATARINA</p>
              <ul>
                <li>
                  <h4>Agência Biguaçu</h4>
                  Rua Avanir Maria Freiberger, 515 - Centro,<br />
                  Biguaçu - Santa Catarina - CEP: 88160-148
                </li>

                <li>
                  <h4>Agência Garopaba</h4>
                  Rua Ismael Lobo, 45 - Centro,<br />
                  Garopaba - Santa Catarina - CEP: 88495-000
                </li>

                <li>
                  <h4>Agência Palhoça</h4>
                  Rua 24 De Abril, 2977 - Centro,<br />
                  Palhoça - Santa Catarina - CEP: 88131-030
                </li>

                <li>
                  <h4>Agência Sul da Ilha</h4>
                  Rod Francisco Magno Vieira, 1408 - Campeche,<br />
                  Florianópolis - Santa Catarina - CEP: 88065-000
                </li>

                <li>
                  <h4>Agência São José</h4>
                  Avenida Lédio João Martins, 125 - Kobrasol,<br />
                  São José - Santa Catarina - CEP: 88101-080
                </li>

                <li>
                  <h4>Agência Tijucas</h4>
                  Rua Leoberto Leal, 100 - Centro,<br />
                  Tijucas - Santa Catarina - CEP: 88200-000
                </li>
              </ul>

              <p>ESTADO DO PARANÁ</p>
              <ul>
                <li>
                  <h4>Agência Foz do Iguaçu</h4>
                  Rua Marechal Floriano Peixoto, 960 - Centro,<br />
                  Foz do Iguaçu - Paraná - CEP: 88010-250
                </li>

                <li>
                  <h4>Agência Medianeira</h4>
                  Rua João XXIII, 1874 - Centro,<br />
                  Medianeira - PR - CEP: 85884000
                </li>
              </ul>

              <p>ESTADO DE SÃO PAULO</p>
              <ul>
                <li>
                  <h4>Agência Jairo Ramos</h4>
                  Rua dos Otonis, nº 742/738 - Vila Clementino,<br />
                  São Paulo - SP - CEP: 04025002
                </li>
              </ul>
            </div>

            <p>
              Programe-se, o futuro da cooperativa depende de sua participação.<br />
              Haverá sorteio de brindes entre todos os cooperados que permanecerem online até o fim da AGEO.
            </p>
            <p>Esperamos você!</p>

            <p style={{ color: '#a2863c' }}>#seguimosjuntos  #valorcapital</p>
          </div>
        </div>
      </div>

      <div className="section-links" style={{ backgroundColor: "#191817" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/valor-capital/edital.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-valor-capital-relatorio-de-gestao.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Relatório de Gestão
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-valor-capital-demonstracao-financeiras.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Demonstrações Financeiras
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-valor-capital-proposta-de-estatuto-social-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Proposta de Estatuto Social
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-manual-web-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso computador
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-manual-mobile-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso celular
                </a>
              </li>
            </ul>
          </div>

          <div className="section-links-info">
            <h1>Ficou com alguma dúvida?</h1>
            <p>Converse com o seu Gerente de Relacionamento ou envie sua dúvida para o e-mail <a href="mailto:marketing.florianopolis@unicred.com.br">marketing.florianopolis@unicred.com.br</a> ou WhatsApp <span><img src={iconWhatsapp} /></span> <a href="https://wa.me/5548996238954" target="_blank">(48) 9 9623-8954</a>.</p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="https://unicred.com.br" target="_blank">unicred.com.br</a>

            <div className="footer-info-box">
              <h2 style={{ marginBottom: 0 }}>Central de Relacionamento Unicred</h2>
              <h2>24 horas por dia, 7 dias por semana</h2>
              <p>3003 7703 (Capitais e Regiões Metropolitanas)</p>
              <p>0800 200 7302 (Demais Localidades)</p>
              <p>+55 800 200 7302 (WhatsApp)</p>
              <p>+55 11 3003 7703 (Ligações do Exterior)</p>
              <p>Chat no app Unicred Mobile e Internet Banking</p>
            </div>

            <div className="footer-info-box">
              <h2>SAC</h2>
              <p>0800 647 2930</p>
            </div>

            <div className="footer-info-box">
              <h2>Ouvidoria</h2>
              <p>0800 940 0602</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredbrasil/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredbrasil" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/unicredbrasil" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCdowVMAIRENRC2VH0_gcdww" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredbr" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unicred;
