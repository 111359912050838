import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconTwitter from "../../../assets/landingpage/unicred/twitter.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";

import "./styles.scss";

function UnicredUniao({ domain }) {
  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  return (
    <div className="lp-unicred">
      <div className="section-main">
        <div className="section-shadow" />
        <div className="section-main-container wrap">
          <div className="section-main-info">
            <h1>
              Bem-vindo
              <span>à Pré-Assembleia da Unicred União</span>
            </h1>

            <p>A ONU proclamou 2025 como o Ano Internacional das Cooperativas, um momento significativo para refletirmos sobre valores e os impactos das cooperativas no mundo.</p>
            <p>Na Unicred União, estamos comprometidos com a promoção do cooperativismo e o fortaleciomento da nossa comunidade.</p>
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#191817" }}>
        <div className="wrap">
          <div className="section-text-container">
            <div className="section-text">
              <h1>Nossos Resultados e Conquistas</h1>
              <p>Convidamos você a assistir aos vídeos destacando as principais entregas e resultados da Unicred União neste último ano e na gestão 2021 - 2024. Nestes materiais, você poderá conferir:</p>
              <p>
                <strong>Crescimento Sustentável:</strong> Números que refletem nossa evolução e amplo reconhecimento no setor financeiro.<br />
                <strong>Impacto Social:</strong> Projetos e iniciativas que beneficiaram nossos cooperados e promoveram o desenvolvimento da nossa comunidade.<br />
                <strong>Inovação e Tecnologia:</strong> Como estamos integrando novas soluções digitais para melhorar a experiência de nossos associados.<br />
                <strong>Educação Financeira:</strong> Nossas ações para capacitar cooperados e a comunidade em geral, promovendo o conhecimento e a gestão consciente de recursos.
              </p>
            </div>

            <div className="section-text">
              <h1>Participe e Contribua</h1>
              <p>Acreditamos que cada um de nossos cooperados tem um papel fundamental na construção de uma cooperativa mais forte e respeitada. Sua voz é essencial para entender as necessidades e expectativas de todos, e seu engajamento é crucial para o sucesso de nossas iniciativas.</p>
              <p>Acesse os vídeos abaixo e venha se inspirar com as conquistas que alcançamos juntos!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo">
        <div className="section-2-container wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">Mauro <span>Marquiotti</span></h1>
            <p>Presidente da Unicred União</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-presidencia-2025.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#e9e9e9" }}>
        <div className="section-2-container wrap">
          <div className="section-2-video-left">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-diretoria.m4v" />
              </video>
            </div>
          </div>

          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">Marcelo <span>Vieira Martins</span></h1>
            <p style={{ color: "#2f2b28" }}>Diretor Executivo da Unicred União</p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#191817" }}>
        <div className="section-2-container wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">Relatório de <span>Gestão</span></h1>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-relatorio-de-gestao.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#202020" }}>
        <div className="wrap">
          <div className="section-form-info">
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Vamos juntos celebrar o impacto positivo do cooperativismo e construir um futuro ainda mais promissor na Unicred União! 
            </p>

            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Participe da Assembleia Geral Ordinária da Unicred União!
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Data: 12 de março de 2025 <br />
              Horário: 19h (terceira convocação)
              Formato: Digital e com transmissão em todas as 25 agências.
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Acesse a assembleia e vote pelo internet banking ou pelo aplicativo Unicred Mobile, através do ícone "assembleias".
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Sua participação é importante para o futuro da nossa cooperativa.
            </p>
          </div>
        </div>
      </div>

      <div className="section-links" style={{ backgroundColor: "#191817" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-edital-de-convocacao.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital de Convocação
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-demonstracoes-financeiras.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Demonstrações Financeiras
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-relatorio-de-gestao.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Relatório de Gestão
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-proposta-da-chapa.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Proposta da Chapa
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-uniao-chapa-do-conselho-de-adm.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Chapa do Conselho de Administração
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-manual-web-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso computador
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2025/unicred-manual-mobile-2025.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso celular
                </a>
              </li>
            </ul>
          </div>

          <div className="section-links-info">
            <h1>Ficou com alguma dúvida ou quer enviar alguma sugestão?</h1>
            <p>
              Converse com seu gerente de relacionamento ou envie diretamente no canal de contato com o presidente da cooperativa:<br /><br />
              <a href="mailto:falecomapresidencia@unicred.com.br">falecomapresidencia@unicred.com.br</a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="https://unicred.com.br" target="_blank">unicred.com.br</a>

            <div className="footer-info-box">
              <h2>Agência Mais</h2>
              <p>Atendimento 24 horas por dia, 7 dias por semana.</p>
              <p>Esclarecimento de dúvidas, contribuição com sugestões, reclamações, cancelamento de serviços e elogios.</p>
              <p>Ligue: 4007-2440</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredbrasil/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredbrasil" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/unicredbrasil" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCdowVMAIRENRC2VH0_gcdww" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredbr" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnicredUniao;
